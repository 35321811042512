export const urls = {


    //project
    getProject:"project",
    createProject:"project/create_project",
    updateProject:"project/update_project",
    deleteProject:"project/delete",
    getProjectName:"project/get-all-projectname",
    fetchProjectsAndTasks:"project/get-project-and-tasks",
    fetchProjectTaskStatus:"project/get-projectTask-status",

    //task
    getTask:"/project/task",
    createTask:"/project/task/create_task",
    updateTask:"/project/task/update_task",
    deleteTask:"/project/task/delete",
    updateTaskSequence:"/project/task/update-task-sequences",


    //employee
    getAllEmployee:"employee/getAllEmployees",
    createEmployee:"employee/create_employee",
    getEmployeeBasedOnPagination:"employee",
    updateEmployee:"employee/update_employee",
    deleteEmployee:"employee/delete_employee",
    getTaskEmployee:"employee/getAllEmployeesAndTask",
    getEmployeeWithProject:"employee/get-all-employees-having-project",

     //tasklist
     taskList:"tasklist",
     createTaskList:"tasklist",
     getEmployeeBasedOnPagination:"employee",
     updateEmployee:"employee/update_employee",
     deleteEmployee:"employee/delete_employee",
     getTaskEmployee:"employee/getAllEmployeesAndTask",
     getEmployeeWithProject:"employee/get-all-employees-having-project",

     templates:"templates",

}