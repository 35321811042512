import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AppDatePicker from "@crema/components/AppFormComponents/AppDateFieldRegular";
import { Button, FormControl, InputLabel, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { differenceInMonths, addMonths } from "date-fns";

type Props = {
  fromDate: any;
  setFromDate: (date: any) => void;
  toDate: any;
  setToDate: (date: any) => void;
  fetchProjectTaskStatus?: (searchTerm?: string) => void;
  setSearchTerm?: (value: string | null) => void;
  searchTerm?: string | null;
};

const AppHeader = (props: Props) => {
  const { fromDate, toDate, setFromDate, setToDate, fetchProjectTaskStatus, searchTerm, setSearchTerm } = props;
  // Create local state to track if we've just cleared search
  const [hasJustClearedSearch, setHasJustClearedSearch] = useState(false);

  // Use useEffect to handle the fetchProjectTaskStatus call after state updates
  useEffect(() => {
    if (hasJustClearedSearch) {
      fetchProjectTaskStatus?.();
      setHasJustClearedSearch(false);
    }
  }, [hasJustClearedSearch, fetchProjectTaskStatus]);

  const handleValidate = () => {
    const maxDifferenceInMonths = 3;
    const dateDifference = differenceInMonths(new Date(toDate), new Date(fromDate));
  
    if (dateDifference <= maxDifferenceInMonths) {
      // Check if there's a search term that needs to be cleared
      if (searchTerm) {
        setSearchTerm(null); // Clear search term
        setHasJustClearedSearch(true); // Set flag to trigger useEffect
      } else {
        // If no search term to clear, just fetch directly
        fetchProjectTaskStatus?.();
      }
    } else {
      toast.error("The date difference should not exceed 3 months.");
    }
  };
  
  const handleToDateChange = (date: any) => {
    setToDate(date);
    const fromDateThreeMonthsAgo = addMonths(new Date(date), -3);
    setFromDate(fromDateThreeMonthsAgo);
  };

  const handleSearch = () => {
    // Only clear dates if they are set
    if (fromDate || toDate) {
      setFromDate(null);
      setToDate(null);
    }
    fetchProjectTaskStatus?.(searchTerm);
  };

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        justifyContent: "space-between",
        gap: 2,
        p: 2, // Padding for better spacing
      }}
    >
      <Grid container spacing={2} alignItems="center">
        {/* Search Field */}
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            variant="outlined"
            label="Search"
            value={searchTerm || ""}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ minWidth: "200px", maxWidth: "400px" }}
          />
        </Grid>

        {/* Search Button */}
        <Grid item xs={12} md={2}>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleSearch}
            fullWidth
            sx={{ minWidth: "100px", maxWidth: "200px" }}
          >
            Search
          </Button>
        </Grid>

        {/* Date Picker */}
        <Grid item xs={12} md={4}>
          <FormControl sx={{ minWidth: "200px", maxWidth: "400px" }}>
            <InputLabel shrink htmlFor="to-date">
              Deadline
            </InputLabel>
            <AppDatePicker
              defaultValue={toDate}
              onChange={(date) => handleToDateChange(date)}
            />
          </FormControl>
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12} md={2}>
          <Button 
            variant="contained" 
            color="primary" 
            type="button"
            onClick={handleValidate}
            fullWidth
            sx={{ minWidth: "100px", maxWidth: "200px" }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AppHeader;